<template>
  <div class="layout layout__default">
    <main class="main">
      <layout-sidebar v-if="!isInterfaceHidden" />
      <div
        :class="[ 'main__content', { 'main__content--frame-mode': isInterfaceHidden }]"
        ref="content"
      >
        <layout-header v-if="!isInterfaceHidden" />
        <div v-if="!isInterfaceHidden" class="accent-block">
          <layout-search v-if="!isAdminPageOpened" />
          <layout-admin-search v-else />
          <layout-banner-links v-if="!isAdminPageOpened" />
        </div>
        <div class="page-wrapper">
          <router-view />
          <esmp-loader
            v-show="!loading.app && loading.page"
            size="large"
            fix
          />
          <iframe class="main__content-trigger" ref="trigger" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import throttle from 'lodash/throttle';
import Hub from '@/plugins/event-hub';

const LayoutSidebar = () => import('@/layouts/sidebar/');
const LayoutHeader = () => import('@/layouts/header/');
const LayoutBannerLinks = () => import('@/layouts/banner-links/');
const LayoutSearch = () => import('@/layouts/search/');
const LayoutAdminSearch = () => import('@/layouts/search/LayoutAdminSearch.vue');

export default {
  name: 'LayoutDefault',
  components: {
    LayoutSidebar,
    LayoutHeader,
    LayoutBannerLinks,
    LayoutSearch,
    LayoutAdminSearch,
  },
  computed: {
    ...mapState('system', ['loading']),
    ...mapGetters('viewOptions', ['isInterfaceHidden']),
    isAdminPageOpened() {
      return this.$route.path.startsWith('/admin');
    },
  },
  methods: {
    // eslint-disable-next-line
    scrollHandler: throttle(function () {
      Hub.$emit('scroll', this.$refs.content.scrollTop);
    }, 100),
    // eslint-disable-next-line
    resizeHandler: throttle(function () {
      Hub.$emit('resize');
    }, 100),
  },
  mounted() {
    this.$refs.content.addEventListener('scroll', this.scrollHandler);
    this.$refs.trigger.contentWindow.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy() {
    this.$refs.content.removeEventListener('scroll', this.scrollHandler);
    this.$refs.trigger.contentWindow.removeEventListener('resize', this.resizeHandler);
  },
  watch: {
    /*
    * esmp-loader в шаблоне нужен, у нас их два!
    * 1 - лоадер для всего app (this.$EsmpLoader), первичная загрузка
    * 2 - лоадер контента страницы <esmp-loader />
    * */
    loading: {
      handler(val) {
        if (val.app) this.$EsmpLoader.show();
        else this.$EsmpLoader.hide();
      },
      deep: true,
      immediate: true,
    },
    $route(val) {
      if (val.name === 'Tickets') {
        this.$refs.content.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.main {
  $root: &;
  width: 100%;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  height: 100vh;
  overflow: hidden;
  background-color: $color-grayscale-05;

  &__content {
    width: calc(100vw - #{$sidebar-width});
    padding: 0 40px 30px;
    transition: width $base-animation-time;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    @include for-size(phone-landscape-down) {
      padding: 0 24px 24px;
    }
    @include for-size(phone-portrait-down) {
      padding: 0 20px 20px;
    }
    &--frame-mode {
      padding: 0;
      background-color: $color-white;
    }
  }

  &__content-trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    visibility: hidden;
    pointer-events: none;
  }

  &--collapse {
    #{$root}__content {
      width: calc(100vw - #{$sidebar-collapse-width});
    }
  }
}
.page-wrapper {
  position: relative;
  width: 100%;
  flex: 1;
}
</style>
